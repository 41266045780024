.ant-table-content {
  width: 96% !important;
}
.ant-table-placeholder {
  display: none;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  padding: 5px !important;
}
.ant-table-thead > tr > th {
  background-color: transparent;
}
.ant-table-header-scope1 div > div > div > div > div > table > thead > tr > th {
  background-color: #4f81bd;
}
.ant-col > div > h4.ant-typography {
  font-size: 150%;
  margin-top: 1em;
}
.table-row-dark-scope1,
.table-row-dark-resume1 {
  background-color: #4f81bd !important;
  color: black !important;
  font-weight: 500;
  font-size: 15px;
}
.table-row-dark-item1 {
  background-color: #4f81bd !important;
  color: black !important;
  font-weight: 500;
  font-size: 15px;
}
.ant-table-header-scope2 div > div > div > div > div > table > thead > tr > th {
  background-color: #9bbb59;
}
.table-row-dark-scope2,
.table-row-dark-resume2 {
  background-color: #9bbb59 !important;
  color: black !important;
  font-weight: 500;
  font-size: 15px;
}
.table-row-dark-item2 {
  background-color: #9bbb59 !important;
  color: black !important;
  font-weight: 500;
  font-size: 15px;
}
.ant-table-header-scope3 div > div > div > div > div > table > thead > tr > th {
  background-color: #c0504d;
}
.table-row-dark-scope3,
.table-row-dark-resume3 {
  background-color: #c0504d !important;
  color: black !important;
  font-weight: 500;
  font-size: 15px;
}
.table-row-dark-item3 {
  background-color: #c0504d !important;
  color: black !important;
  font-weight: 500;
  font-size: 15px;
}
.ant-table-header-scope4 div > div > div > div > div > table > thead > tr > th {
  background-color: #000000;
  color: white !important;
}
.table-row-dark-scope4 {
  background-color: #000000 !important;
  color: white !important;
  font-weight: 500;
  font-size: 15px;
}
.table-row-dark-resume5 {
  background-color: #4a7c2c !important;
  color: white !important;
  font-weight: 500;
  font-size: 15px;
}

.checkbox-plants .ant-checkbox-group-item {
  min-width: 200px;
}
